.single-product-container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #fff;
  margin-top: 10px;
}

@media (min-width: 300px) {
  .single-product-description-desktop {
    display: none;
  }
  .single-product-description-mobile {
    display: block;
  }
  .single-product-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 395px;
  }
  .single-product-container-image {
    display: block;
    width: 395px;
    margin: 0 auto;
    padding: 1rem;
  }
  .single-product-container-image img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 500px) {
  .single-product-description-desktop {
    display: none;
  }
  .single-product-description-mobile {
    display: block;
  }
  .single-product-inner {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .single-product-container-image {
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
  }
  .single-product-container-info {
    display: block;
    padding: 0 1rem;
    width: 100%;
  }
  .single-product-description-mobile,
  .single-product-rating {
    display: block;
    padding: 0.5rem 0;
  }

  .single-product-container-image img {
    display: block;
    width: 100%;
  }
  .single-product-container-price1 {
    display: block;
    width: 100%;
    padding: 1rem 1rem;
  }
  .single-product-price1 {
    display: block;
    color: red;
    font-size: 1.5rem;
    font-weight: 700;
    padding-bottom: 0.5rem;
  }

  .add-button-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;
  }

  .add-button-container .add-to-cart-v2-button-inner {
    height: 100%;
    margin: 10px 0;
  }
  .add-to-favorite-inner {
    width: 15%;
    padding: 0.5rem;
    padding-right: 0.5rem;
    padding-left: 0;
  }
  .add-to-favorite {
    display: block;
    width: 100%;
    padding: 0.5rem 0;
    border-radius: 5px;
    border: 1px solid #000;
  }

  .add-to-cart-inner {
    width: 100%;
    padding: 0.5rem 0;
  }
  .add-to-cart {
    display: block;
    width: 100%;
    padding: 0.5rem 0;
    border-radius: 5px;
    border: 1px solid #003f7b;
    background-color: #003f7b;
    color: #fff;
  }
}

@media (min-width: 660px) {
  .single-product-container {
    width: 650px;
  }
  .single-product-description-desktop {
    display: block;
    width: 100%;
    margin: 0;
    padding: 1rem;
  }
  .single-product-inner {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;

    align-items: start;
    width: 100%;
  }
  .single-product-container-image {
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
  }
  .single-product-container-image img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .add-button-container {
    display: flex;

    flex-direction: column;
    flex-flow: column-reverse;
    width: 100%;
  }
  .add-to-favorite-container {
    display: flex;
    width: 100%;
  }
  .single-product-container-price1 {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 10px;
    border: none;
  }
}
@media (min-width: 768px) {
  .single-product-container {
    width: 740px;
  }
  .single-product-description-desktop {
    display: block;
    width: 100%;
    margin: 0;
    padding: 1rem;
  }
  .single-product-inner {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;

    align-items: start;
    width: 100%;
  }
  .single-product-container-image {
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
  }
  .single-product-container-image img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .add-button-container {
    display: flex;

    flex-direction: column;
    flex-flow: column-reverse;
    width: 100%;
  }
  .add-to-favorite-container {
    display: flex;
    width: 100%;
  }
  .single-product-container-price1 {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 10px;
    border: none;
  }
}

@media (min-width: 990px) {
  .single-product-container {
    width: 960px;
  }
  .single-product-description-desktop {
    display: block;
    width: 100%;
    margin: 0;
    padding: 1rem;
  }
  .single-product-inner {
    display: flex;
    flex-direction: row;
    align-items: start;
    width: 100%;
  }
  .single-product-container-image {
    display: block;
    width: 50%;
    margin: 0 auto;
    padding: 1rem;
  }
  .single-product-container-image img {
    display: block;
    width: 100%;
    height: 100%;
  }
  .single-product-container-info {
    width: 50%;
  }
  .single-product-container-price1 {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 10px;
    border: none;
    width: 50%;
  }
}
@media (min-width: 1260px) {
  .single-product-container {
    width: 1220px;
  }
  .single-product-description-desktop {
    display: block;
    width: 100%;
    margin: 0;
    padding: 1rem;
  }
  .single-product-inner {
    display: flex;
    flex-direction: row;
    align-items: start;
    width: 100%;
  }
  .single-product-container-image {
    display: block;
    width: 50%;
    margin: 0 auto;
    padding: 1rem;
  }
  .single-product-container-image img {
    display: block;
    width: 100%;
    height: 100%;
  }
  .single-product-container-info {
    width: 50%;
  }
  .single-product-container-price1 {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 10px;
    border: none;
    width: 50%;
  }
}

@media (min-width: 1530px) {
  .single-product-container {
    width: 1520px;
  }
  .single-product-container-info {
    width: 100%;
  }
  .single-product-inner {
    display: flex;
    flex-direction: row;
    align-items: start;
    width: 100%;
  }
  .single-product-container-price1 {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 10px;
    border: none;
  }
}
