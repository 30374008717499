.filters-v2-container {
  margin: 10px 0;
}
.filters-v2-container .filter-head {
  color: #000;
  text-decoration: none;
  font-weight: 700;
  padding: 10px 10px;
  padding-right: 40px;
  font-size: 14px;
}

.filter-head span {
  position: relative;
}
.filter-head span::after {
  content: "";
  position: absolute;
  left: 240px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
}

.filter-item {
  display: block;
}

.filter-body {
  padding: 0 10px;
  font-size: 14px;
}

.filter-inner {
  padding: 5px 0;
}

.filter-inner label {
  cursor: pointer;
  padding-left: 10px;
}

.scrollable {
  max-height: 210px;
  overflow-y: auto;
}

.scrollable::-webkit-scrollbar {
  width: 0.5rem;
}

.scrollable::-webkit-scrollbar-button:single-button:vertical:increment {
  background-image: url("../../../../Assets//icons/chevron-down-solid.svg");
}
.scrollable::-webkit-scrollbar-button:single-button:vertical:decrement {
  background-image: url("../../../../Assets//icons/chevron-up-solid.svg");
}

.scrollable::-webkit-scrollbar-track {
  background: transparent;
}

.scrollable::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 35px;
  border: 1px solid white;
}

.scrollable::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.55);
}

.star-brand-text {
  padding: 0 5px;
}

/* Start brand Category */

.brand-v2-container {
  margin: 10px 0;
  background-color: #fff;
}

/* End brand Category */

/* Start models Category */

.model-v2-container {
  margin: 10px 0;
  background-color: #fff;
}

.filter-body a {
  display: block;
}

/* End brand Category */
