.control-group div:last-child {
  margin-left: auto;
}

.tip-afisare-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cards-view-list,
.cards-view-grid {
  display: inline-block;
}

.tip-afisare-head {
  font-weight: 400;
  font-size: 14px;
  padding: 5px;
}

.tip-afisare-body {
  padding: 5px;
}
.tip-afisare-body i {
  font-size: 14px;
}

.tip-afisare-body button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 10px 10px;
}

.cards-view-list {
  border: 1px solid #ccc;
  border-right: transparent;
}

.cards-view-grid {
  border: 1px solid #ccc;
  background-color: transparent;
}

.cards-view-list.card-v2-list {
  background-color: #e0e0e0;
}

.cards-view-grid.card-v2-grid {
  background-color: #e0e0e0;
}

/* Start list Cards */

@media (min-width: 500px) {
  .filter {
    display: none;
  }
  .listing-panel-container {
    display: block;
  }

  .container-products-outer {
    width: 100%;
  }
  .card-collection {
    margin: -5px -5px -5px;
    width: 768px;
  }
  .page-container {
    display: block;
    float: none;
    width: fit-content;
    height: fit-content;
  }
  .cards-container-outer .card-collection {
    display: flex;
    width: 100%;
  }
  .card-collection.card-v2-list .card-item {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .card-v2.card-v2-list .card-v2-wrapper {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
  .card-collection.card-v2-list .card-v2-info {
    width: 100%;
  }

  .card-v2.card-v2-list .card-v2-thumb {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  .card-v2.card-v2-list .card-v2-thumb-inner {
    width: 100%;
  }
  .card-v2.card-v2-list .pad-description {
    width: 100%;
  }

  .card-collection.card-v2-list .card-v2-content {
    width: 25%;
  }

  .sort-control-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media (min-width: 600px) {
  .filter {
    display: none;
  }
  .listing-panel-container {
    display: block;
  }

  .container-products-outer {
    width: 100%;
  }
  .card-collection {
    margin: -5px -5px -5px;
    width: 768px;
  }
  .page-container {
    display: block;
    float: none;
    width: fit-content;
    height: fit-content;
  }
  .cards-container-outer .card-collection {
    display: flex;
    width: 100%;
  }
  .card-collection.card-v2-list .card-item {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .card-v2.card-v2-list .card-v2-wrapper {
    display: flex;
    flex-direction: row;
    padding: 1rem;
  }
  .card-collection.card-v2-list .card-v2-info {
    width: 75%;
  }

  .card-v2.card-v2-list .card-v2-thumb {
    display: flex;
    flex-direction: row;
    justify-content: start;
  }
  .card-v2.card-v2-list .card-v2-thumb-inner {
    width: 55%;
  }
  .card-v2.card-v2-list .pad-description {
    width: 73%;
  }

  .card-collection.card-v2-list .card-v2-content {
    width: 25%;
  }
}

@media (min-width: 760px) {
  .filter {
    display: none;
  }
  .listing-panel-container {
    display: block;
  }

  .container-products-outer {
    width: 100%;
  }
  .card-collection {
    margin: -5px -5px -5px;
    width: 768px;
  }
  .page-container {
    display: block;
    float: none;
    width: fit-content;
    height: fit-content;
  }
  .cards-container-outer .card-collection {
    display: flex;
    width: 100%;
  }
  .card-collection.card-v2-list .card-item {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .card-v2.card-v2-list .card-v2-wrapper {
    display: flex;
    flex-direction: row;
    padding: 1rem;
  }
  .card-collection.card-v2-list .card-v2-info {
    width: 75%;
  }

  .card-v2.card-v2-list .card-v2-thumb {
    display: flex;
    flex-direction: row;
    justify-content: start;
  }
  .card-v2.card-v2-list .card-v2-thumb-inner {
    width: 55%;
  }
  .card-v2.card-v2-list .pad-description {
    width: 73%;
  }

  .card-collection.card-v2-list .card-v2-content {
    width: 25%;
  }
}
@media (min-width: 768px) {
  .filter {
    display: none;
  }
  .listing-panel-container {
    display: block;
  }

  .container-products-outer {
    width: 100%;
  }
  .card-collection {
    margin: -5px -5px -5px;
    width: 768px;
  }
  .page-container {
    display: block;
    float: none;
    width: fit-content;
    height: fit-content;
  }
  .cards-container-outer .card-collection {
    display: flex;
    width: 100%;
  }
  .card-collection.card-v2-list .card-item {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .card-v2.card-v2-list .card-v2-wrapper {
    display: flex;
    flex-direction: row;
    padding: 1rem;
  }
  .card-collection.card-v2-list .card-v2-info {
    width: 75%;
  }

  .card-v2.card-v2-list .card-v2-thumb {
    display: flex;
    flex-direction: row;
    justify-content: start;
  }
  .card-v2.card-v2-list .card-v2-thumb-inner {
    width: 55%;
  }
  .card-v2.card-v2-list .pad-description {
    width: 73%;
  }

  .card-collection.card-v2-list .card-v2-content {
    width: 25%;
  }
}
@media (min-width: 878px) {
  .filter {
    display: none;
  }
  .listing-panel-container {
    display: block;
  }

  .container-products-outer {
    width: 100%;
  }
  .card-collection {
    margin: -5px -5px -5px;
    width: 768px;
  }
  .page-container {
    display: block;
    float: none;
    width: fit-content;
    height: fit-content;
  }
  .cards-container-outer .card-collection {
    display: flex;
    width: 100%;
  }
  .card-collection.card-v2-list .card-item {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .card-v2.card-v2-list .card-v2-wrapper {
    display: flex;
    flex-direction: row;
    padding: 1rem;
  }
  .card-collection.card-v2-list .card-v2-info {
    width: 75%;
  }

  .card-v2.card-v2-list .card-v2-thumb {
    display: flex;
    flex-direction: row;
    justify-content: start;
  }
  .card-v2.card-v2-list .card-v2-thumb-inner {
    width: 55%;
  }
  .card-v2.card-v2-list .pad-description {
    width: 73%;
  }

  .card-collection.card-v2-list .card-v2-content {
    width: 25%;
  }
}
@media (min-width: 992px) {
  .container-products-outer {
    width: 100%;
  }
  .card-collection {
    margin: -5px -5px -5px;
    width: 768px;
  }
  .page-container {
    display: block;
    float: none;
    width: fit-content;
    height: fit-content;
  }
  .cards-container-outer .card-collection {
    display: flex;
    width: 100%;
  }
  .card-collection.card-v2-list .card-item {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .card-v2.card-v2-list .card-v2-wrapper {
    display: flex;
    flex-direction: row;
    padding: 1rem;
  }
  .card-collection.card-v2-list .card-v2-info {
    width: 75%;
  }

  .card-v2.card-v2-list .card-v2-thumb {
    display: flex;
    flex-direction: row;
    justify-content: start;
  }
  .card-v2.card-v2-list .card-v2-thumb-inner {
    width: 55%;
  }
  .card-v2.card-v2-list .pad-description {
    width: 73%;
  }

  .card-collection.card-v2-list .card-v2-content {
    width: 25%;
  }
}
@media (min-width: 1500px) {
  .container-products-outer {
    width: 100%;
  }
  .card-collection {
    margin: -5px -5px -5px;
    width: 768px;
  }
  .page-container {
    display: block;
    float: none;
    width: fit-content;
    height: fit-content;
  }
  .cards-container-outer .card-collection {
    display: flex;
    width: 100%;
  }
  .card-collection.card-v2-list .card-item {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .card-v2.card-v2-list .card-v2-wrapper {
    display: flex;
    flex-direction: row;
    padding: 1rem;
  }
  .card-collection.card-v2-list .card-v2-info {
    width: 75%;
  }

  .card-v2.card-v2-list .card-v2-thumb {
    display: flex;
    flex-direction: row;
    justify-content: start;
  }
  .card-v2.card-v2-list .card-v2-thumb-inner {
    width: 40%;
  }
  .card-v2.card-v2-list .pad-description {
    width: 73%;
  }

  .card-collection.card-v2-list .card-v2-content {
    width: 25%;
  }
}
@media (min-width: 1530px) {
  .container-products-outer {
    width: 100%;
  }
  .card-collection {
    margin: -5px -5px -5px;
    width: 768px;
  }
  .page-container {
    display: block;
    float: none;
    width: fit-content;
    height: fit-content;
  }
  .cards-container-outer .card-collection {
    display: flex;
    width: 100%;
  }
  .card-collection.card-v2-list .card-item {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .card-v2.card-v2-list .card-v2-wrapper {
    display: flex;
    flex-direction: row;
    padding: 1rem;
  }
  .card-collection.card-v2-list .card-v2-info {
    width: 75%;
  }

  .card-v2.card-v2-list .card-v2-thumb {
    display: flex;
    flex-direction: row;
    justify-content: start;
  }
  .card-v2.card-v2-list .card-v2-thumb-inner {
    width: 35%;
  }
  .card-v2.card-v2-list .pad-description {
    width: 73%;
  }

  .card-collection.card-v2-list .card-v2-content {
    width: 25%;
  }
}

/* End list Cards */
