html,
body {
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;

  overflow-x: hidden;
  overflow-y: auto;
}

div {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
