.navbar-aux-products-body {
  position: relative;
}
.navbar-aux-products-body .navbar-aux-products-container {
  position: absolute;
  top: 45px;
  background-color: white;
}
.navbar-aux-products-body.products .navbar-aux-products-container {
  position: absolute;
  top: 10px;
  background-color: white;
}
