.container-products-outer {
  display: block;
  width: 1520px;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  padding-top: 10px;
}

.sort-options {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 350px;
  padding: 0 10px;
}

.container-filtere {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 1000px;
}

.limit,
.sort {
  padding-right: 10px;

  text-align: center;
}

.limit select {
  background-color: transparent;
  border: 1px solid white;
  background-color: #fff;
  padding: 0.2rem;
  width: 100px;
  text-align: center;
}

.sort select {
  background-color: transparent;
  border: 1px solid white;
  background-color: #fff;
  padding: 0.2rem;
  width: 100px;
  text-align: center;
}

@media screen and (min-width: 200px) {
  .container-products-outer {
    display: block;
    width: 100vw;
  }
}
@media screen and (min-width: 1520px) {
  .container-products-outer {
    display: block;
    width: 1520px;
    height: 100%;
    margin: 0 auto;
    padding: 0 10px;
    padding-top: 10px;
  }
}
