.add-to-favorite-v2-button-inner {
  width: 100%;
  margin: 10px 0;
}

.add-to-favorite-v2-button-inner .btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  background-color: #fff;
  color: #222222;
  border: 1px solid transparent;
  white-space: nowrap;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 7px 12px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #005eb8;
  background-color: #fff;
  border-color: #005eb8;
  width: 100%;
}
