.model-title {
  background-color: #fff;
  padding: 1rem;
}

.model-title .filter-head {
  color: black;
  display: block;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0;
}
.model-title label {
  cursor: pointer;
}

.model-filter-inner label {
  cursor: pointer;
}
/* .model-title .filter-head::after {
  border-style: solid;
  border-width: 0.2em 0.2em 0 0;
  content: "";
  display: inline-block;
  height: 0.45em;
  left: 5em;
  position: absolute;
  text-align: end;
  top: 1;
  transform: rotate(135deg);
  vertical-align: top;
  width: 0.45em;
  margin-left: 12rem;
} */
