* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif;
}

.container-header {
  background-color: #fff;
  width: 100%;
}
.container-header .container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.menu-inner ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
}

.add-to-cart-icon-inner {
  position: relative;
}

/* .add-to-cart-icon::before {
  position: relative;
  font-family: "Bootstrap-icons";
  content: "\F243";
  font-size: 22px;
  padding: 5px;
} */

.add-to-cart-li {
  position: relative;
}

.add-to-cart-li a {
  display: flex;
  align-items: center;
  padding: 10px;
}

.add-to-cart-li:hover .add-to-cart-modal-container {
  display: block;
}

.menu-inner ul li {
  margin: 0 10px;
  list-style: none;
}

.menu-inner ul li a {
  text-decoration: none;
  color: #000;
  font-weight: 500;
}

/* isAdmin */
.user-profile {
  position: relative;
}

.bun-venit:hover {
  cursor: pointer;
}

.category-inner {
  display: block;
  position: absolute;
  width: 200px;
  left: -50px;
  padding-top: 1rem;
  text-align: center;
  background-color: transparent;
}
.bun-venit ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.bun-venit ul li {
  padding: 0.5rem;
}

/* .bun-venit ul {
  display: none;
} */
.bun-venit:hover ul {
  display: block;
}

.btn-logout {
  background-color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
}

.brand-logo {
  text-decoration: none;
  color: #000;
  text-transform: uppercase;
}

.add-to-cart-li .cart-text::after {
  content: "";
  position: absolute;
  left: 80px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
}

.sort-items {
  display: flex;
  padding: 10px 5px 0 0;
}
.sort-control-item {
  padding-top: 5px;
}
.limit-items {
  display: flex;
  padding: 10px 5px 0 0;
}
.limit-control-item {
  padding-top: 5px;
}

@media (min-width: 50px) {
  .container-header .container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .container-brand {
    display: block;
    padding: 5px 0px;

    text-align: center;
  }
  .menu-inner ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .menu-inner ul li a {
    display: block;
    margin: 0.5rem;
    padding: 0.5rem;
    line-height: 0;
  }
  .add-to-cart-a::after {
    display: block;
    float: left;
    font-family: "Bootstrap-icons";
    content: "\F243";
    font-size: 22px;
    padding: 5px;
    position: relative;
    bottom: 10px;
  }
}
@media (min-width: 200px) {
  .container-header .container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .menu-inner ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .menu-inner ul li a {
    display: block;
    margin: 0.5rem;
    padding: 0.5rem;
    line-height: 0;
  }
  .container-brand {
    display: block;
    padding: 10px;
    text-align: center;
  }
  .add-to-cart-a::after {
    display: block;
    float: left;
    font-family: "Bootstrap-icons";
    content: "\F243";
    font-size: 22px;
    padding: 5px;
    position: relative;
    bottom: 10px;
  }
}
@media (min-width: 300px) {
  .container-header {
    margin: 0;
    padding: 0;
  }
  .container-header .container {
    display: flex;
    flex-direction: column;
    width: 300px;
  }
  .menu-inner ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .menu-inner ul li a {
    display: block;
    margin: 0.5rem;
    padding: 0.5rem;
    line-height: 0;
  }
  .add-to-cart-a::after {
    display: block;
    float: left;
    font-family: "Bootstrap-icons";
    content: "\F243";
    font-size: 22px;
    padding: 5px;
    position: relative;
    bottom: 10px;
  }
}
@media (min-width: 300px) {
  .container-header .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 400px) {
  .container-header .container {
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 500px) {
  .container-header .container {
    width: 500px;
    display: flex;
    flex-direction: row;
  }
  .menu-inner ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .menu-inner ul li a {
    padding: 10px 0px;
    margin: 15px 0;
  }
}
@media (min-width: 600px) {
  .container-header .container {
    width: 600px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 700px) {
  .container-header .container {
    width: 700px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  .container-header .container {
    width: 768px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 878px) {
  .container-header .container {
    width: 878px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 992px) {
  .container-header .container {
    width: 960px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 1200px) {
  .container-header .container {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 1260px) {
  .container-header .container {
    width: 1220px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 1500px) {
  .container-header .container {
    width: 1500px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 1530px) {
  .container-header .container {
    width: 1520px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
