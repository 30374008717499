.card-collection {
  display: flex;
  flex-wrap: wrap;
}

.card-v2 {
  width: 100%;
  background-color: #fff;
  margin: 5px;
  border-radius: 5px;
}

.card-v2 .card-v2-wrapper:hover {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);
}
.card-v2 .card-v2-wrapper {
  min-height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  position: relative;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-transition: box-shadow 0.15s ease;
  transition: box-shadow 0.15s ease;
}

.card-collection .card-item {
  display: flex;
  flex-wrap: wrap;
}

.card-v2-wrapper a {
  text-decoration: none;
  color: #222;
}

.card-v2 .card-v2-title-wrapper {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
}

.card-v2-info img {
  text-align: center;
}
.card-v2-thumb {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 5px;
}

.card-v2-thumb-inner {
  text-align: center;
  height: 270px;
}

.card-v2-thumb-inner img {
  padding: 5px;
  height: 100%;
  width: 100%;
}

.pad-description {
  padding-left: 10px;
  padding-right: 10px;
}

.card-estimate-placeholder span {
  color: #888;
  font-size: 12px;
  text-transform: lowercase;
  font-weight: 600;
}

.card-v2-content .card-v2-price {
  padding-bottom: 10px;
}

.card-v2 .card-v2-content {
  padding: 0 10px 10px 10px;
}

.card-v2-content .product-new-price .decimal {
  opacity: 0.5;
  font-size: 1px;
}
.product-new-price {
  font-size: 18px;
  margin: 0;

  white-space: nowrap;
  /* font-family: "Open Sans", Helvetica, sans-serif; */
  color: #ef2809;
  font-weight: 700;
}
.product-new-price span {
  padding: 0 5px;
}

.card-v2-add-to-cart .add-to-cart-v2-button {
  height: 32px;
}

.card-estimate-placeholder .danger-stock {
  color: #ef2809;
}
.card-estimate-placeholder .warning-stock {
  color: #ef2809;
}
.add-to-cart-item .add-to-cart-close {
  display: block;
  cursor: pointer;
  float: right;
}
/* Start Mobile */

/* @media (min-width: 320px) {
  .main-container-body {
    display: block;
    width: 320px;
    height: 100%;
    margin: auto;
    padding-top: 1rem;
  }
  .container-products-outer {
    width: 320px;
  }
  .container-products-outer .filter {
    display: none;
  }

  .card-collection .main-container-body .listing-panel-container {
    display: block;
    width: 100%;
  }

  .page-container {
    display: block;
    float: none;
    width: 475px;
    height: fit-content;
  }

  .card-collection {
    margin: auto;
  }
  .tip-afisare-head {
    display: block;
  }

  .limit-control-item {
    width: 50px;
  }

  .cards-container-outer .card-collection {
    display: block;
    width: 395px;
  }
  .card-collection.card-v2-grid .card-item {
    width: calc(100% / 1);
    height: 502px;
  }

  .listing-panel-footer {
    height: 100%;
  }

  .sort-control-group .control-group {
    display: flex;
    flex-direction: row;
  }
} */
@media (min-width: 50px) {
  .main-container-body {
    display: block;
    width: 100%;
    height: 100%;
    margin: auto;
    padding-top: 0.5rem;
  }
  .container-products-outer {
    width: 100%;
  }
  .container-products-outer .filter {
    display: none;
  }

  .card-collection .main-container-body .listing-panel-container {
    display: block;
    width: 100vh;
  }

  .page-container {
    display: block;
    float: none;
    width: 100%;
    height: fit-content;
  }

  .card-collection {
    margin: auto;
  }
  .tip-afisare-head {
    display: block;
  }

  .limit-control-item {
    width: 50px;
  }

  .cards-container-outer .card-collection {
    display: block;
    width: 100%;
  }
  .card-collection.card-v2-grid .card-item {
    width: calc(100% / 1);
    height: 502px;
  }

  .card-collection .main-container-body .listing-panel-container {
    display: block;
    width: 275px;
  }

  .listing-panel-footer {
    height: 100%;
  }

  .sort-control-group {
    display: flex;
    flex-direction: column;
  }

  .sort-control-group .control-group {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 200px) {
  .main-container-body {
    display: block;
    width: 100%;
    height: 100%;
    margin: auto;
    padding-top: 0.5rem;
  }
  .container-products-outer {
    width: 100%;
  }
  .container-products-outer .filter {
    display: none;
  }

  .card-collection .main-container-body .listing-panel-container {
    display: block;
    width: 100vh;
  }

  .page-container {
    display: block;
    float: none;
    width: 100%;
    height: fit-content;
  }

  .card-collection {
    margin: auto;
  }
  .tip-afisare-head {
    display: block;
  }

  .limit-control-item {
    width: 50px;
  }

  .cards-container-outer .card-collection {
    display: block;
    width: 100%;
  }
  .card-collection.card-v2-grid .card-item {
    width: calc(100% / 1);
    height: 502px;
  }

  .card-collection .main-container-body .listing-panel-container {
    display: block;
    width: 275px;
  }

  .listing-panel-footer {
    height: 100%;
  }

  .sort-control-group {
    display: flex;
    flex-direction: column;
  }

  .sort-control-group .control-group {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 300px) {
  .main-container-body {
    display: block;
    width: 100%;
    height: 100%;
    margin: auto;
    padding-top: 0.5rem;
  }
  .container-products-outer {
    width: 100%;
  }
  .container-products-outer .filter {
    display: none;
  }

  .card-collection .main-container-body .listing-panel-container {
    display: block;
    width: 100vh;
  }

  .page-container {
    display: block;
    float: none;
    width: 100%;
    height: fit-content;
  }

  .card-collection {
    margin: auto;
  }
  .tip-afisare-head {
    display: block;
  }

  .limit-control-item {
    width: 50px;
  }

  .cards-container-outer .card-collection {
    display: block;
    width: 100%;
  }
  .card-collection.card-v2-grid .card-item {
    width: calc(100% / 1);
    height: 502px;
  }

  .card-collection .main-container-body .listing-panel-container {
    display: block;
    width: 100%;
  }

  .listing-panel-footer {
    height: 100%;
  }

  .sort-control-group {
    display: flex;
    flex-direction: column;
  }

  .sort-control-group .control-group {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 412px) {
  .main-container-body {
    display: block;
    width: 100%;
    height: 100%;
    margin: auto;
    padding-top: 0.5rem;
  }
  .container-products-outer {
    width: 100%;
  }
  .container-products-outer .filter {
    display: none;
  }

  .card-collection .main-container-body .listing-panel-container {
    display: block;
    width: 100%;
  }

  .page-container {
    display: block;
    float: none;
    width: 100%;
    height: fit-content;
  }

  .card-collection {
    margin: auto;
  }
  .tip-afisare-head {
    display: block;
  }

  .limit-control-item {
    width: 50px;
  }

  .cards-container-outer .card-collection {
    display: flex;
    width: 100%;
  }
  .card-collection.card-v2-grid .card-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100% / 2);
    height: 502px;
  }

  .card-collection .main-container-body .listing-panel-container {
    display: block;
    width: 100%;
  }

  .listing-panel-footer {
    height: 100%;
  }
  .control-group {
    width: 100%;
    margin-right: auto;
  }

  .sort-control-group .control-group {
    display: flex;
    flex-direction: column;
  }
  .control-group div:last-child {
    margin-left: 0;
  }
}
@media (min-width: 500px) {
  .main-container-body {
    display: block;
    width: 100%;
    height: 100%;
    margin: auto;
    padding-top: 1rem;
  }
  .container-products-outer {
    width: 500px;
  }
  .container-products-outer .filter {
    display: none;
  }

  .card-collection .main-container-body .listing-panel-container {
    display: block;
    width: 100%;
  }

  .page-container {
    display: block;
    float: none;
    width: 475px;
    height: fit-content;
  }

  .card-collection {
    margin: auto;
  }
  .tip-afisare-head {
    display: block;
  }

  .limit-control-item {
    width: 50px;
  }

  .cards-container-outer .card-collection {
    display: flex;
    width: 100%;
  }
  .card-collection.card-v2-grid .card-item {
    display: flex;
    flex-direction: row;
    width: calc(100% / 2);
    height: 502px;
  }

  .card-collection .main-container-body .listing-panel-container {
    display: block;
    width: 475px;
  }

  .listing-panel-footer {
    height: 100%;
  }

  .sort-control-group .control-group {
    display: flex;
    flex-direction: column;
    width: 60%;
  }
  .sort-control-group {
    display: flex;
    flex-direction: row;
  }
  .control-group div:last-child {
    margin-left: 0;
  }
  .sort-control-btn-dropdown span:after {
    content: "";
    position: absolute;
    left: 150px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
  }

  .limit-control-btn-dropdown {
    position: relative;
  }

  .limit-control-btn-dropdown span:after {
    content: "";
    position: absolute;
    left: 150px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
  }
}

@media (min-width: 600px) {
  .container-products-outer {
    width: 600px;
  }
  .card-collection {
    margin: -5px -5px -5px;
    width: 600px;
  }
  .card-collection .main-container-body .listing-panel-container {
    display: block;
    width: 99%;
  }
  .tip-afisare-head {
    display: block;
  }

  .page-container {
    display: block;
    float: none;
    width: fit-content;
    height: fit-content;
  }
  .cards-container-outer .card-collection {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .card-collection.card-v2-grid .card-item {
    width: calc(100% / 2);
    height: 502px;
  }
  .products-container-v2 {
    width: 600px;
  }
  .sort-control-group .control-group {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 700px) {
  .container-products-outer {
    width: 700px;
  }
  .card-collection {
    margin: -5px -5px -5px;
    width: 700px;
  }
  .page-container {
    display: block;
    float: none;
    width: 100%;
    height: fit-content;
  }
  .products-container-v2 {
    width: 100%;
  }

  .tip-afisare-head {
    display: none;
  }
  .tip-afisare-container {
    width: 13%;
  }
  .cards-container-outer .card-collection {
    display: flex;
    width: 100%;
    justify-content: start;
  }
  .card-collection.card-v2-grid .card-item {
    width: calc(100% / 2);
    height: 502px;
  }

  .sort-control-group .control-group {
    display: flex;
    flex-direction: row;
    width: 80%;
  }
}

@media (min-width: 768px) {
  .container-products-outer {
    width: 768px;
  }
  .card-collection {
    margin: -5px -5px -5px;
    width: 768px;
  }
  .tip-afisare-head {
    display: block;
  }
  .control-group {
    width: 100%;
  }

  .page-container {
    display: block;
    float: none;
    width: 768px;
    height: fit-content;
  }
  .cards-container-outer .card-collection {
    display: flex;
    width: 100%;
  }
  .card-collection.card-v2-grid .card-item {
    width: calc(100% / 3);
    height: 502px;
  }
  .tip-afisare-container {
    width: 23%;
  }
}

@media (min-width: 878px) {
  .container-products-outer {
    width: 868px;
  }
  .page-container,
  .products-container-v2 {
    width: 868px;
  }

  .listing-panel-container {
    display: block;
  }
  .card-collection {
    margin: -5px -5px -5px;
    width: 868px;
  }
  .page-container {
    display: block;
    float: none;
    width: 100%;
    height: fit-content;
  }
  .cards-container-outer .card-collection {
    display: flex;
    width: 100%;
  }
  .card-collection.card-v2-grid .card-item {
    width: calc(100% / 3);
    height: 502px;
  }
  .tip-afisare-container {
    width: 20%;
  }
}

@media (min-width: 992px) {
  .container-products-outer {
    display: block;
    margin: auto;
    width: 992px;
  }

  .listing-panel-container {
    display: block;
  }
  .container-products-outer .filter {
    display: block;
  }

  .card-collection .main-container-body .listing-panel-container {
    display: block;
    width: 99%;
  }

  .page-container {
    display: block;
    float: left;
    margin-top: 1rem;
  }

  .card-collection {
    margin: -5px -5px -5px;
    width: calc(100% / 1.5);
  }

  .page-container {
    display: block;
  }
  .cards-container-outer {
    display: block;
  }
  .tip-afisare-head {
    display: none;
  }

  .cards-container-outer .card-collection {
    display: flex;
    width: 100%;
    justify-content: start;
  }
  .card-collection.card-v2-grid .card-item {
    width: calc(100% / 2);
    height: 502px;
  }
  .sort-control-group .control-group {
    display: flex;
    flex-direction: row;
  }
  .products-container-v2 {
    width: 100%;
  }
  .tip-afisare-container {
    width: 14%;
  }
}

@media (min-width: 1200px) {
  .container-products-outer {
    display: block;
    margin: auto;
    width: 1200px;
  }
  .card-collection {
    width: 880px;
  }
  .tip-afisare-head {
    display: block;
  }
  .card-collection.card-v2-grid .card-item {
    width: calc(100% / 3);
    height: 502px;
  }
  .tip-afisare-container {
    width: 19%;
  }
}
@media (min-width: 1500px) {
  .container-products-outer {
    display: block;
    margin: auto;
    width: 1500px;
  }
  .card-collection {
    width: 1150px;
  }
  .products-container-v2 {
    width: 100%;
  }
  .card-collection.card-v2-grid .card-item {
    width: calc(100% / 4);
    height: 502px;
  }
  .tip-afisare-container {
    width: 15%;
  }
}
@media (min-width: 1530px) {
  .container-products-outer {
    display: block;
    margin: auto;
    width: 1530px;
  }
  .card-collection {
    width: 1150px;
  }
  .products-container-v2 {
    width: 100%;
  }
  .card-collection.card-v2-grid .card-item {
    width: calc(100% / 4);
    /* height: 502px; */
  }
  .tip-afisare-container {
    width: 15%;
  }
}

/* End Mobile */
