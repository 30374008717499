.sidebar-filter-rating-container {
  padding-top: 1rem;
  margin-bottom: 1rem;
}

.sidebar-filter-rating-title {
  position: relative;
  padding-bottom: 0.5rem;
}

.filter-head {
  display: block;
  color: black;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}

.sidebar-filter-rating {
  display: block;
  padding: 1rem;
  background-color: #fff;
}

.sidebar-filter-rating-body:not(.active) {
  display: none;
  padding-left: 0.5rem;
}

.stars {
  display: inline-block;

  margin-bottom: 0.5rem;
}

.form-rating label {
  cursor: pointer;
}

.star-rating-container {
  display: inline-block;
}
.star-rating {
  display: inline-block;
  margin-left: 5px;
}

.star-rating-text {
  display: inline-block;
  margin-left: 5px;
}

.star-rating-text span {
  line-height: 20px;
  font-size: 12px;
  color: #000;
}
