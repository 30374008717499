body {
  background-color: #f5f5f5;
  font-family: "Courier New", Courier, monospace;
}

/* Start Login form */
.container-login {
  width: 100%;
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.container-login section {
  display: block;
  background-color: #fff;
  padding: 2rem;
  width: 400px;
  border-radius: 10px;
}

.container-login form {
  text-align: center;
}

.container-login form input {
  display: block;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.container-login h1 {
  display: block;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.container-login .form-group {
  display: block;
  margin-bottom: 1rem;
}

.container-login .form-group label {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.container-login button {
  display: block;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  font-size: 1.2rem;
  cursor: pointer;
}

/* End Login form */

/* Start Register form */

.container-register {
  width: 100%;
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.container-register section {
  display: block;
  background-color: #fff;
  padding: 2rem;
  width: 400px;
  border-radius: 10px;
}

.container-register form {
  text-align: center;
}

.container-register form input {
  display: block;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.container-register h1 {
  display: block;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.container-register .form-group {
  display: block;
  margin-bottom: 1rem;
}

.container-register .form-group label {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.container-register button {
  display: block;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  font-size: 1.2rem;
  cursor: pointer;
}
/* End Register form */
