:root {
  --ff-primary: basic-sans, sans-serif;
  --clr-primary: #ee6352;
  --clr-body: #333;
  --clr-bg: #ddd;
  --spacer: 1rem;
  --margin: 2px;
  --card-grid: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
h1,
h2,
h3,
p {
  margin: 0;
}

.flow > * + * {
  margin-top: var(--flow-space), var(--spacer);
}

.even-columns {
  display: flex;
}
.even-columns > * {
  flex-basis: 100%;
}
.grid-parent {
  display: flex;
  gap: 1.5rem;
}

.grid-ish {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  width: 100%;
}

.col {
  position: relative;
  /* padding: 3rem; */
  background: rgba(0, 0, 0, 0.1);
  border: 3px solid rgba(0, 0, 0, 0.2);
}
.grid-child2 {
  width: 100%;
}

.image img {
  display: block;
  margin: 0 auto;
}

.col .description,
.price,
.stock,
.butonnes {
  padding: 10px;
}

.grid-child .col {
  margin-bottom: 10px;
}

.butonnes,
button {
  display: block;
  width: 100%;
}

.butonnes button {
  display: block;
  padding: 8px;
  border: none;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
}

.content {
  width: 70vw;
  margin: 3rem auto;
  padding: 2em;
  box-shadow: 0 0 3rem rgba(0, 0, 0, 0.3);
  background: white;
}

@media (min-width: 51px) {
  .grid-child {
    display: none;
  }

  .grid-ish2 .col {
    display: block;
    width: 100%;
  }

  .col {
    width: calc(100% / 1);
  }

  .image img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  .cards-v2 {
    width: 100%;
    margin: 0 auto;
    padding: 0px;
  }
  .content {
    width: 100%;
    margin: 0;
    padding: 10px;
  }
}
@media (min-width: 300px) {
  .grid-parent {
    flex-direction: column;
    gap: 1rem;
  }
  .grid-ish2 .col {
    display: block;
    width: 100%;
  }

  .col {
    width: calc(100% / 1);
  }

  .image img {
    display: block;
    margin: 0 auto;
    width: 200px;
    height: 200px;
  }

  .cards-v2 {
    width: 100%;
    margin: 0 auto;
    padding: 0px;
  }
  .content {
    width: 100%;
    margin: 0;
    padding: 10px;
  }
}
@media (min-width: 400px) {
  .grid-parent {
    flex-direction: column;
    gap: 1rem;
  }
  .grid-ish2 .col {
    display: block;
    width: 100%;
  }

  .col {
    width: calc((100% - 4px) / 2);
  }

  .image img {
    display: block;
    margin: 0 auto;
    width: 200px;
    height: 200px;
  }

  .cards-v2 {
    width: 100%;
    margin: 0 auto;
    padding: 0px;
  }
  .content {
    width: 100%;
    margin: 0;
    padding: 10px;
  }
}
@media (min-width: 500px) {
  .grid-ish2 {
    flex-direction: column;
  }
  .col {
    width: calc((100% - 4px) / 2);
  }
}

@media (min-width: 600px) {
  .grid-ish2 {
    flex-direction: column;
  }
  .col {
    width: calc((100% - 4px) / 1);
  }
  .content {
    width: 60vw;
    margin: 3rem auto;
    padding: 2em;
    box-shadow: 0 0 3rem rgba(0, 0, 0, 0.3);
    background: white;
  }
  .content {
    width: 70vw;
    margin: 3rem auto;
    padding: 2em;
    box-shadow: 0 0 3rem rgba(0, 0, 0, 0.3);
    background: white;
  }
}
@media (min-width: 700px) {
  .grid-ish2 {
    flex-direction: column;
  }
  .col {
    width: calc((100% - 4px) / 1);
  }
}
@media (min-width: 768px) {
  .grid-ish2 {
    flex-direction: row;
  }
  .col {
    width: calc((100% - 4px) / 2);
  }
}
@media (min-width: 1000px) {
  .col {
    width: calc((100% - 8px) / 3);
  }
}

@media (min-width: 1200px) {
  .grid-child {
    display: inline-block;
  }
  .grid-parent {
    flex-direction: row;
  }
  .grid-child .col {
    width: 320px;
  }
  .col {
    width: calc((100% - 4px) / 2);
  }
  .content {
    width: 70vw;
    margin: 3rem auto;
    padding: 2em;
    box-shadow: 0 0 3rem rgba(0, 0, 0, 0.3);
    background: white;
  }
}
@media (min-width: 1400px) {
  .col {
    width: calc((100% - 8px) / 3);
  }
}

@media (min-width: 1700px) {
  .col {
    width: calc((100% - 12px) / 4);
  }
}

.content-sidebar {
  display: flex;

  flex-wrap: wrap;
}
.content-sidebar > *:nth-child(1) {
  flex: 1 1 70%;
  min-width: 30ch;
}
.content-sidebar > *:nth-child(2) {
  flex: 1 1 30%;
  min-width: 15ch;
}

.title {
  font-weight: 900;
  color: var(--clr-primary);
  text-align: center;
}
