.navbar-aux-outer {
  background: linear-gradient(
    to right,
    #ff1d25 5%,
    #a0328c 35%,
    #7040a4 45%,
    #4359c7 55%,
    #0082e6 70%
  );
}
.navbar-aux-outer {
  line-height: 45px;
}

.navbar-aux-outer .container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.navbar-aux-outer.tick {
  height: 5px;
}
.navbar-aux-outer.tick .container {
  display: none;
}

.navbar-aux-head {
  position: relative;
  line-height: 42px;
  width: 83px;
}
.navbar-aux-head.tick {
  position: relative;
  line-height: 45px;
  width: 83px;
}

.navbar-aux-head-inner {
  position: absolute;
  top: 4px;
  background-color: white;
  padding: 0 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
}

.navbar-aux-head-inner.tick {
  position: relative;
  padding: 0 10px;
  background-color: white;
  border-radius: 5px;
  top: 4px;
  line-height: 38px;
  cursor: pointer;
}

.navbar-aux-head-inner span {
  position: relative;
  top: -4px;
}
.navbar-aux-head-inner.tick span {
  position: relative;
  top: 0;
}

.navbar-box-inner ul li a {
  margin: 6px;
}
.navbar-box-inner ul li a:hover {
  border: 1px solid white;
  margin: 5px;
  border-radius: 15px;
}

.navbar-box-footer ul {
  list-style: none;
}

.navbar-box-footer ul li a {
  text-decoration: none;
  color: white;
  margin: 5px;
  padding: 0.5rem;
}

.navbar-box-footer ul li a:hover {
  border: 1px solid white;
  border-radius: 15px;
  margin: 5px;
  cursor: pointer;
}

@media (min-width: 100px) {
  .navbar-aux-outer {
    width: 100%;
  }
  .navbar-aux-outer .container {
    width: 100%;
  }

  .navbar-box-inner ul {
    display: block;
    position: absolute;
    top: 45px;
    left: -70px;
    width: 100%;
    background-color: white;
  }

  .navbar-aux-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
  .navbar-aux-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
  .navbar-box-inner {
    display: block;
    position: relative;
    z-index: 1;
  }
  .navbar-box-inner ul {
    list-style: none;
    border: 1px solid #ccc;
    width: 160px;
    right: 0;
  }
  .navbar-box-inner ul li a {
    display: block;
    text-decoration: none;
    color: black;
    padding: 5px;
    line-height: 24px;
    text-align: center;
  }
  .navbar-box-inner ul li a:hover {
    display: block;
    text-decoration: none;
    color: black;
    padding: 5px;
    line-height: 22px;
    background-color: #f5f5f5;
    text-align: center;
    border-radius: 2px;
  }
  .navbar-aux-button-others {
    position: relative;
  }
  .navbar-aux-button-others-inner {
    position: absolute;
    top: 4px;
    background-color: white;
    padding: 0 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
  }
  .navbar-aux-button-others-inner span {
    position: relative;
    bottom: 3px;
  }
}
@media (min-width: 250px) {
  .navbar-aux-outer {
    width: 100%;
  }
  .navbar-aux-outer .container {
    width: 100%;
  }

  .navbar-box-inner ul {
    display: none;
    position: absolute;
    top: 45px;
    right: 16px;
    width: 100%;
    background-color: white;
  }

  .navbar-aux-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
  .navbar-aux-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
  .navbar-box-inner {
    display: block;
    position: relative;
    z-index: 1;
  }
  .navbar-box-inner ul {
    list-style: none;
    border: 1px solid #ccc;
    width: 140px;

    right: -125px;
  }
  .navbar-box-inner ul li a {
    display: block;
    text-decoration: none;
    color: black;
    padding: 5px;
    line-height: 24px;
    text-align: center;
  }
  .navbar-box-inner ul li a:hover {
    display: block;
    text-decoration: none;
    color: black;
    padding: 5px;
    line-height: 22px;
    background-color: #f5f5f5;
    text-align: center;
    border-radius: 2px;
  }
  .navbar-aux-button-others {
    position: relative;
  }
  .navbar-aux-button-others-inner {
    position: absolute;
    top: 4px;
    background-color: white;
    padding: 0 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
  }
  .navbar-aux-button-others-inner span {
    position: relative;
    bottom: 3px;
  }
}
@media (min-width: 412px) {
  .navbar-aux-outer {
    width: 100%;
  }
  .navbar-aux-outer .container {
    width: 100%;
  }

  .navbar-box-inner ul {
    position: absolute;
    top: 45px;
    background-color: white;
  }

  .navbar-aux-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
  .navbar-aux-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
  .navbar-box-inner {
    display: block;
  }
  .navbar-box-inner ul {
    list-style: none;
    border: 1px solid #ccc;
  }
  .navbar-box-inner ul li a {
    display: block;
    text-decoration: none;
    color: black;
    padding: 5px;
    font-size: 14px;
  }
  .navbar-box-inner ul li a:hover {
    display: block;
    text-decoration: none;
    color: black;
    padding: 5px;
    background-color: #f5f5f5;
  }
}
@media (min-width: 500px) {
  .navbar-aux-outer {
    width: 100%;
  }
  .navbar-aux-outer .container {
    width: 500px;
  }

  .navbar-box-inner ul {
    position: absolute;
    top: 45px;
    background-color: white;
  }

  .navbar-aux-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
  .navbar-aux-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
  .navbar-box-inner {
    display: block;
  }
  .navbar-box-inner ul {
    list-style: none;
    border: 1px solid #ccc;
  }
  .navbar-box-inner ul li a {
    display: block;
    text-decoration: none;
    color: black;
    padding: 5px;
    font-size: 14px;
  }
  .navbar-box-inner ul li a:hover {
    display: block;
    text-decoration: none;
    color: black;
    padding: 5px;
    background-color: #f5f5f5;
  }
}

@media (min-width: 600px) {
  .navbar-aux-outer {
    width: 100%;
  }
  .navbar-aux-outer .container {
    width: 600px;
  }

  .navbar-box-inner ul {
    position: absolute;
    top: 45px;
    background-color: white;
  }

  .navbar-aux-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
  .navbar-aux-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
  .navbar-box-inner {
    display: block;
  }
  .navbar-box-inner ul {
    list-style: none;
    border: 1px solid #ccc;
  }
  .navbar-box-inner ul li a {
    display: block;
    text-decoration: none;
    color: black;
    padding: 5px;
    font-size: 14px;
  }
  .navbar-box-inner ul li a:hover {
    display: block;
    text-decoration: none;
    color: black;
    padding: 5px;
    background-color: #f5f5f5;
  }
}
@media (min-width: 700px) {
  .navbar-aux-outer {
    width: 100%;
  }
  .navbar-aux-outer .container {
    width: 700px;
  }

  .navbar-box-inner ul {
    position: absolute;
    top: 45px;
    background-color: white;
  }

  .navbar-aux-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
  .navbar-aux-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
  .navbar-box-inner {
    display: block;
  }
  .navbar-box-inner ul {
    list-style: none;
    border: 1px solid #ccc;
  }
  .navbar-box-inner ul li a {
    display: block;
    text-decoration: none;
    color: black;
    padding: 5px;
    font-size: 14px;
  }
  .navbar-box-inner ul li a:hover {
    display: block;
    text-decoration: none;
    color: black;
    padding: 5px;
    background-color: #f5f5f5;
  }
}
@media (min-width: 768px) {
  .navbar-aux-outer {
    width: 100%;
  }
  .navbar-aux-outer .container {
    width: 768px;
  }
  .navbar-aux-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
  .navbar-aux-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
  .navbar-box-inner {
    display: flex;
    flex-direction: row;
    width: 95%;
    right: 10px;
  }
  .navbar-box-inner ul {
    position: relative;
    background-color: transparent;
    border: none;
    top: 0;
  }
  .navbar-box-inner ul li {
    display: flex;
    flex-direction: row;
    margin: 0;
    width: 100%;
  }

  .navbar-box-inner ul li a {
    text-decoration: none;
    color: white;
    padding: 1px 4px;
    margin: 5px;
    font-size: 14px;
    line-height: 38px;
  }
  .navbar-box-inner ul li a:hover {
    text-decoration: none;
    color: white;
    padding: 0px 3px;
    margin: 5px;
    background-color: transparent;
    text-align: center;
    font-size: 14px;
  }

  .navbar-aux-button-others-inner {
    display: none;
  }
}
@media (min-width: 878px) {
  .navbar-aux-outer {
    width: 100%;
  }
  .navbar-aux-outer .container {
    width: 878px;
  }
  .navbar-aux-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
  .navbar-aux-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
  .navbar-box-inner {
    display: flex;
    flex-direction: row;
    width: 95%;
    right: 10px;
  }
  .navbar-box-inner ul {
    position: relative;
    background-color: transparent;
    border: none;
    top: 0;
  }
  .navbar-box-inner ul li {
    display: flex;
    flex-direction: row;
    margin: 0;
    width: 100%;
  }

  .navbar-box-inner ul li a {
    text-decoration: none;
    color: white;
    padding: 1px 4px;
    margin: 5px;
    font-size: 14px;
    line-height: 38px;
  }
  .navbar-box-inner ul li a:hover {
    text-decoration: none;
    color: white;
    padding: 0px 3px;
    margin: 5px;
    background-color: transparent;
    text-align: center;
    font-size: 14px;
  }

  .navbar-aux-button-others-inner {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-aux-outer .container {
    width: 960px;
  }
}
@media (min-width: 1200px) {
  .navbar-aux-outer {
    width: 100%;
  }
  .navbar-aux-outer .container {
    width: 1200px;
  }
  .navbar-aux-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
  .navbar-aux-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
  .navbar-box-inner {
    display: flex;
    flex-direction: row;
    width: 95%;
    right: 10px;
  }
  .navbar-box-inner ul {
    position: relative;
    background-color: transparent;
    border: none;
    top: 0;
  }
  .navbar-box-inner ul li {
    display: flex;
    flex-direction: row;
    margin: 0;
    width: 100%;
  }

  .navbar-box-inner ul li a {
    text-decoration: none;
    color: white;
    padding: 1px 4px;
    margin: 5px;
    font-size: 14px;
    line-height: 38px;
  }
  .navbar-box-inner ul li a:hover {
    text-decoration: none;
    color: white;
    padding: 0px 3px;
    margin: 5px;
    background-color: transparent;
    text-align: center;
    font-size: 14px;
  }

  .navbar-aux-button-others-inner {
    display: none;
  }
}
@media (min-width: 1260px) {
  .navbar-aux-outer .container {
    width: 1240px;
  }
}
@media (min-width: 1530px) {
  .navbar-aux-outer .container {
    width: 1520px;
  }
}
