.add-to-cart-modal-container {
  display: none;
  position: absolute;
  top: 46px;
  left: -90px;
  z-index: 1;
  border-radius: 10px;
  /* padding-top: 20px; */
}

.add-to-cart-modal-inner {
  background-color: white;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
}

.add-to-cart-modal-title {
  padding: 10px;

  border-bottom: 1px #eee solid;
  font-size: 10px;
  color: #005eb8;
}

.add-to-cart-modal-body {
  display: flex;
  height: 280px;
  width: 290px;
  flex-direction: column;

  overflow-y: auto;
}
.add-to-cart-modal-body a {
  padding: 0px;
}
.add-to-cart-modal-body .in {
  display: flex;
  flex-direction: row;
  border-bottom: 1px #eee solid;
  padding: 5px;
  font-size: 11px;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  width: 100%;
  z-index: 2;
}

.in .inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.in .footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
  position: relative;
}

.add-to-cart-modal-body .in:hover {
  background-color: #f8f8f8;
  cursor: pointer;
}

.add-to-cart-modal-body .modal-link {
  position: relative;
  padding: 0;
}
.add-to-cart-modal-description {
  width: 130px;
}
.add-to-cart-modal-quantity {
  position: relative;
  width: 50px;
  z-index: 6;
}

.add-to-cart-modal-amount {
  font-weight: bold;
  color: #005eb8;
  width: 20%;
}
.add-to-cart-modal-close {
  position: absolute;
  top: 0;
  left: 265px;
  z-index: 7;
  padding: 5px;
}
.add-to-cart-modal-close:hover {
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 265px;
}

.add-to-cart-modal-close span {
  display: block;
  width: 100%;
  height: 100%;
}

.add-to-cart-modal-body::-webkit-scrollbar {
  width: 0.5rem;
}
.add-to-cart-modal-body::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 35px;
  border: 1px solid white;
}
.add-to-cart-modal-body::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 35px;
}

.add-to-cart-modal-total-quantity {
  display: block;
  background-color: white;
  color: #888;
  font-weight: bold;
  font-size: 85%;
  overflow: hidden;
  border-top: 1px solid #e5e5e5;
  padding: 5px;
}
.add-to-cart-modal-total-quantity span {
  display: block;
  float: left;
  padding: 0 2px;
}

.add-to-cart-modal-total-amount {
  display: block;
  background-color: white;
  color: #888;
  font-weight: bold;
  font-size: 85%;
  overflow: hidden;
  border-top: 1px solid #e5e5e5;
  padding: 5px;
}

.add-to-cart-modal-cart-button {
  border-top: 1px solid #e5e5e5;
  padding: 10px;
}

.add-to-cart-modal-total-amount:last-of-type {
  float: right;
  font-weight: bold;
  font-size: 85%;
  border: 0;
}

.modal-empty-cart {
  padding: 10px 0;
  font-size: 18px;
  color: #888;
}

.add-to-cart-modal-cart-inner {
  padding: 5px;
}
.add-to-cart-modal-cart-inner .cart-btn {
  display: block;
  width: 100%;
  color: white;
  background: #3985e0;
  background: -webkit-linear-gradient(left, #4a60c1 10%, #3985e0);
  background: linear-gradient(to right, #4a60c1 10%, #3985e0);
  border-radius: 5px;
  line-height: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-width: 0;
  text-decoration: none;
}

.add-to-cart-modal-quantity .group-info {
  display: flex;
  position: relative;
  gap: 2px;
}

.add-to-cart-modal-quantity .group-info div {
  font-size: 12px;
}
.add-to-cart-modal-quantity .group-info button {
  position: relative;
  z-index: 4;
  font-size: 12px;
  width: 15px;
  height: 15px;
}
.add-to-cart-modal-quantity .group-info button:hover {
  background-color: white;
  position: relative;
  z-index: 1;
  font-size: 12px;
  width: 15px;
  height: 15px;
}

.add-to-cart-modal-close {
  position: absolute;
  background-color: #f8f8f8;
  border: transparent;
  top: 2px;
  left: 264px;
  padding: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  font-size: 14px;
}
.add-to-cart-modal-close span {
  position: relative;
  top: -10px;
  right: 3px;
}

.add-to-cart-modal-close:hover {
  position: absolute;
  background-color: white;
  border: transparent;
  top: 2px;
  left: 264px;
  padding: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  font-size: 14px;
  cursor: pointer;
}
